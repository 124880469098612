.page-connection {
    .name {
        margin-bottom: 0;

        .delay {
            display: inline-block;
            font-weight: 400;
            margin-left: 10px;
            font-size: 18px;
            background-color: #3faa73;
            padding: 5px 10px;
            color: white;
            vertical-align: middle;
        }
    }

    .provider {
        font-size: 0.9em;
        color: grey;
    }

    .back-homepage {
        svg {
            cursor: pointer;
            height: 30px;
        }
    }

    .desc {
        margin-top: 10px;
    }

    .delay-round {
        display: inline-block;
        margin-left: 5px;
        background-color: #e6e6e6;
        padding: 2px 10px;
        font-size: 12px;
    }

    .time-table {
        margin-top: 40px;

        .title {
            display: grid;
            grid-template-columns: 30px auto 50px 50px 80px;
            text-transform: uppercase;
            color: grey;
        }

        .data-frame {
            position: relative;

            .stations {
                height: 500px;
                overflow-y: auto;
                z-index: 5;

                .station {
                    padding: 10px 0;
                    border-top: 1px solid #ededed;

                    .table {
                        display: grid;
                        grid-template-columns: 30px auto 50px 50px 80px;
                    }

                    .delay {
                        display: inline-block;
                        margin-left: 10px;
                        font-size: 0.8em;
                        padding: 3px 5px;
                        opacity: 0.8;
                    }

                    .round {
                        border: 1px solid transparent;
                        background-color: $bg-own-green-500;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;

                        &.passed {
                            background-color: transparent;
                            border: 1px solid $bg-own-green-500;
                        }
                    }

                    .waiting-for {
                        margin-left: 30px;
                        color: grey;
                        font-size: 0.8em;
                    }
                }
            }

            .graph {
                position: absolute; top: 0; left: 0;
                height: 100%;
                width: 0px;
                background-color: red;
            }
        }
    }

    .route {
        margin: 20px 0;
        max-width: 400px;
        width: 100%;

        .town {
            display: flex;
            justify-content: space-between;
        }
    }
}