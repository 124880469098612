.page-homepage {
    .found {
        margin-top: 20px;

        .scrollbox {
            overflow-y: auto;
            height: 400px;
        }

        .connection {
            cursor: pointer;
            border-top: 1px solid #ebebeb;
            padding: 25px 10px;

            .name {
                font-weight: 700;
                font-size: 20px;
            }

            .provider {
                color: grey;
            }

            .route {
                margin-top: 20px;
                max-width: 400px;

                .stop {
                    display: grid;
                    grid-template-columns: auto 80px;
                }
            }

            &:hover {
                background-color: #ebebeb;
            }
        }
    }
}

.input-box {
    background-color: #f6f8ff;
    padding: 15px 15px 15px 30px;
    display: flex;
    align-items: center;
    align-items: center;
    border-radius: 10px;

    input {
        background-color: transparent;
        border: 0;
        outline: none;
        width: calc(100% - 20px);
        font-size: 1.5em;
        color: $text-dark;
    }

    .divider {
        height: 30px;
        border-right: 1px solid #929292;
        display: inline-block;
        margin: 0 10px;
    }

    svg {
        color: #929292;
        height: 20px;
    }
}